import { FC, memo } from 'react';
import { GoogleLoginProps } from './types';
import { StyledGoogleLoginContainer } from './GoogleLogin.css';
import { StytchLogin } from '@stytch/nextjs';
import { useResize } from '@morf/ui';
import { useTheme } from '@morf/theming';
import {
  OAuthProviders,
  Products,
  StyleConfig,
  StytchLoginConfig,
} from '@stytch/vanilla-js';

const GoogleLogin: FC<GoogleLoginProps> = ({ from }) => {
  const theme = useTheme();

  const {
    colors: {
      main: {
        primary: { darker },
      },
      ui,
      text,
    },
  } = theme;

  const responsiveWidth = useResize({
    width: {
      xxl: '25rem',
      xl: '25rem',
      lg: '25rem',
      md: '25rem',
      sm: '20rem',
      xs: '20rem',
    },
  });

  const sdkStyle: StyleConfig = {
    fontFamily: 'apercu-regular',
    hideHeaderText: true,
    container: {
      borderColor: text.inverse,
      width: responsiveWidth.width,
    },
    colors: {
      primary: darker,
      secondary: ui.divider,
    },
    buttons: {
      primary: {
        borderColor: darker,
        textColor: text.inverse,
        backgroundColor: darker,
      },
      secondary: {
        borderColor: darker,
        borderRadius: '10px',
        textColor: text.inverse,
        backgroundColor: darker,
      },
    },
  };

  const origin = () => {
    if (typeof window !== 'undefined') {
      return window.location.origin || '';
    }
    return null;
  };

  const domain = origin();
  const domainLink = domain ? `${domain}/authenticate` : '/';
  const redirectLink = from ? `?from=${encodeURIComponent(from)}` : '';

  const sdkConfig: StytchLoginConfig = {
    products: [Products.oauth],
    oauthOptions: {
      providers: [{ type: OAuthProviders.Google }],
      loginRedirectURL: domainLink + redirectLink,
      signupRedirectURL: domainLink,
    },
  };

  return (
    <StyledGoogleLoginContainer
      data-testid='google-login'
      width='auto'
      height='auto'
    >
      <StytchLogin config={sdkConfig} styles={sdkStyle} />
    </StyledGoogleLoginContainer>
  );
};

export const MemoizedGoogleLogin = memo(GoogleLogin);
