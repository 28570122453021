import { styled } from '@morf/theming';

export const StyledLogo = styled.div`
  position: absolute;
  top: -2rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: ${(props) => props.theme.borderRadius['full']};
  background-color: ${(props) => props.theme.colors.main.light.darker};
  width: 4rem;
  height: 4rem;
`;
