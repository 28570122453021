import { FC, memo } from 'react';
import { Flexbox, Text, Icon } from '@morf/ui';
import { GoogleLogin } from './GoogleLogin';
import { StyledLogo } from './Login.css';
import { useTheme } from '@morf/theming';
import { useUrlParams } from '../helpers/useUrlParams';

const Login: FC = () => {
  const theme = useTheme();
  const { from } = useUrlParams();

  return (
    <Flexbox justifyContent='center' alignItems='center' height='100vh'>
      <Flexbox
        backgroundColor={theme.colors.ui.card}
        direction='column'
        justifyContent='center'
        alignItems='center'
        position='relative'
        width='auto'
        height='auto'
        pt={4}
        pb={1}
      >
        <StyledLogo>
          <Flexbox alignItems='center' justifyContent='center'>
            <Icon
              name='morf-logo'
              size={2}
              stroke={theme.colors.main.primary.dark}
            />
          </Flexbox>
        </StyledLogo>

        <Text tag='h1'>Login to Morf</Text>
        <GoogleLogin from={from} />
      </Flexbox>
    </Flexbox>
  );
};

export const MemoizedLogin = memo(Login);
